


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CONVERT_BANK_ACCOUNT } from '../../../store/bankAccount';
import { BankAccountDetails, ConvertBankAccount } from '../../../types';
import { ApiResponse } from '@/components/types';
import BankAccountTypeSelect from '../../BankAccountTypeSelect.vue';

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    BankAccountTypeSelect
  }
})
export default class BankAccountConvertDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: BankAccountDetails;
  @Prop({ type: Boolean, required: true }) dialog!: boolean;
  @BankAccount.Action(CONVERT_BANK_ACCOUNT) convertBankAccount!: (convert: ConvertBankAccount) => Promise<ApiResponse>;

  error: any = null;

  value: ConvertBankAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    type: this.account.type
  };

  get iDialog() {
    return this.dialog;
  }

  set iDialog(dialog: boolean) {
    this.$emit('update:dialog', dialog);
  }

  @Watch('iDialog')
  onDialogChange() {
    this.value = {
      finance_account_id: this.account.id,
      list_of_assets_id: this.account.listOfAssetsId,
      type: this.account.type
    };
  }

  save() {
    this.error = null;

    return this.convertBankAccount(this.value);
  }
}
