















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import { ApiResponse } from '@/components/types';
import { Bank } from '../types';

const Bank = namespace('bank');

@Component
export default class BankSelect extends Vue {
  @Prop({ type: String }) value?: string;

  @Bank.Getter(GET_LIST) list!: Bank[];
  @Bank.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  loading = false;

  get items() {
    return this.list.map((bank) => ({ ...bank, name: `${bank.name} (${bank.bankCode})` }));
  }

  onInput(value: string) {
    if (!value) {
      this.$emit('input', null)
      this.$emit('on-select', null)
      return
    }

    this.$emit('input', value)
    this.$emit('on-select', this.list.find(b => b.id == value))
  }

  async created() {
    if (this.list.length !== 0) return;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  }
}
