
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { BankAccountType } from '../types';
import { bankAccount } from '../statics';

@Component({
  inheritAttrs: false
})
export default class BankAccountTypeSelect extends Vue {
  @Prop({ type: [Array, String] }) value!: null | BankAccountType | BankAccountType[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;

  get list(): Array<{ value: BankAccountType, text: TranslateResult }> {
    return bankAccount.map((type) => ({ value: type, text: this.$i18n.t(`financeAccount.type.${type}`) }));
  }

  created() {
    this.$emit('input', this.value);
  }
}
