















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_BANK_ACCOUNTS, GET_BANK_ACCOUNTS } from '../store/bankAccount';
import { FETCH_CASH_ACCOUNTS, GET_CASH_ACCOUNTS } from '../store/cashAccount';
import { FETCH_CREDIT_ACCOUNTS, GET_CREDIT_ACCOUNTS } from '../store/creditAccount';
import { ApiResponse } from '@/components/types';
import { BankAccount, CashAccount, CreditAccount } from '../types';

const BankAccount = namespace('listOfAssets/bankAccount');
const CreditAccount = namespace('listOfAssets/creditAccount');
const CashAccount = namespace('listOfAssets/cashAccount');

@Component({
  inheritAttrs: false
})
export default class FinanceAccountSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String }) exclude?: string;
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  @BankAccount.Getter(GET_BANK_ACCOUNTS) listBankAccounts!: (listOfAssets: string) => BankAccount[];
  @BankAccount.Action(FETCH_BANK_ACCOUNTS) fetchBankAccounts!: (listOfAssets: string) => Promise<ApiResponse>;

  @CashAccount.Getter(GET_CASH_ACCOUNTS) listCashAccounts!: (listOfAssets: string) => CashAccount[];
  @CashAccount.Action(FETCH_CASH_ACCOUNTS) fetchCashAccounts!: (listOfAssets: string) => Promise<ApiResponse>;

  @CreditAccount.Getter(GET_CREDIT_ACCOUNTS) listCreditAccounts!: (listOfAssets: string) => CreditAccount[];
  @CreditAccount.Action(FETCH_CREDIT_ACCOUNTS) fetchCreditAccounts!: (listOfAssets: string) => Promise<ApiResponse>;

  get items() {
    return [
      ...this.listBankAccounts(this.listOfAssetsId),
      ...this.listCashAccounts(this.listOfAssetsId),
      ...this.listCreditAccounts(this.listOfAssetsId)
    ]
      .filter((financeAccount) => financeAccount.id !== this.exclude && financeAccount.state === 'open')
      .map((financeAccount: BankAccount | CashAccount | CreditAccount) => {
        if (financeAccount.category === 'bank_account') {
          return {
            name: `${(financeAccount as BankAccount).iban} (${(financeAccount as BankAccount).bank.name})`,
            id: financeAccount.id
          };
        }

        const type = this.$i18n.t(`financeAccount.type.${financeAccount.type}`);

        if (financeAccount.category === 'credit_account') {
          return {
            name: `${type} (${(financeAccount as CreditAccount).creditInstitute} - ${(financeAccount as CreditAccount).creditNumber})`,
            id: financeAccount.id
          };
        }

        const note = financeAccount.note ? `(${financeAccount.note})` : '';

        return { name: `${type} ${note}`.trim(), id: financeAccount.id };
      });
  }

  created() {
    this.fetchBankAccounts(this.listOfAssetsId).catch((error) => {
      console.log(error);
    });
    this.fetchCashAccounts(this.listOfAssetsId).catch((error) => {
      console.log(error);
    });
    this.fetchCreditAccounts(this.listOfAssetsId).catch((error) => {
      console.log(error);
    });
  }
}
