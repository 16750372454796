












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CardRow from '@/components/CardRow.vue';
import { CANCEL_TRANSACTION } from '../../../store/financeAccount';
import { CancelTransaction, Transaction } from '../../../types';
import { ApiResponse } from '@/components/types';
import { createCancelTransaction } from '@/modules/listOfAssets/model';
import HtmlRow from '@/components/HtmlRow.vue';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    HtmlRow,
    CardRow
  }
})
export default class CancelTransactionDialog extends Vue {
  @Prop({ type: Object }) transaction!: Transaction;

  @FinanceAccount.Action(CANCEL_TRANSACTION) cancelTransaction!: (value: CancelTransaction) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: CancelTransaction | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:transaction', null);
    }, 200);
  }

  @Watch('transaction')
  watchTransaction(transaction: Transaction) {
    if (!transaction) return;

    this.value = createCancelTransaction(transaction);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.cancelTransaction(this.value);
  }
}
