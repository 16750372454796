









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_OPENED } from '../../../store/financeAccount';
import { ChangeFinanceAccountOpened, FinanceAccountDetails } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class ChangeFinanceAccountOpenedDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: FinanceAccountDetails;

  @FinanceAccount.Action(CHANGE_OPENED) changeOpened!: (account: ChangeFinanceAccountOpened) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: ChangeFinanceAccountOpened = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    category: this.account.category,
    opened: this.account.opened
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        category: this.account.category,
        opened: this.account.opened
      };

      return;
    }

    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeOpened(this.value);
  }
}
