




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import { FinanceAccount } from '../types';

@Component({
  components: {
    ErrorAlert,
    DatePicker
  }
})
export default class TransactionExportDialog extends Vue {
  @Prop({ type: String, required: true }) account!: FinanceAccount;
  @Prop({ type: String, default: format(Date.now(), 'yyyy-MM-dd') }) startDate!: string;
  @Prop({ type: String, default: format(Date.now(), 'yyyy-MM-dd') }) endDate!: string;
  @Prop({ type: String, default: 'transactions' }) downloadName!: string;
  @Prop({ type: String, default: '' }) search!: string;

  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;

  itemsPerPage: number = 25;
  end: string = '';
  start: string = '';
  file: string = '';

  get download() {
    return `${this.downloadName}-${this.end}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value === false) {
      setTimeout(() => {
        this.error = null;
        this.file = '';
        this.loading = false;
        // @ts-ignore
        this.$refs.form.reset();
      }, 200);

      return;
    }

    this.itemsPerPage = 25;
    this.start = this.startDate;
    this.end = this.endDate;
  }

  minDate(date: string) {
    return date >= this.startDate;
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/financial-administration/finance-account/${this.account}/transactions-search-pdf`, {
        responseType: 'blob',
        params: { start: this.start, end: this.end, search: this.search, itemsPerPage: this.itemsPerPage }
      });

      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
