






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppDropzone from '@/modules/fileManagement/components/AppDropzone.vue';
import { Transaction } from '@/modules/listOfAssets/types';

@Component({
  components: {
    AppDropzone,
    ErrorAlert
  }
})
export default class VoucherUploadDialog extends Vue {
  @Prop({ type: Object }) transaction!: Transaction | null;

  error: any = null;
  dialog: boolean = false;
  close: Function = () => {};

  baseURL: string = process.env.VUE_APP_API || `https://${document.location.host}/backend`;

  get url(): string {
    if (!this.transaction) return ''

    return `${this.baseURL}/api/financial-administration/${this.transaction.id}/add-voucher`
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    this.error = null;
    this.$emit('update:transaction', null);
    this.close();
  }

  @Watch('transaction')
  watchFolder(transaction: Transaction | null) {
    if (!transaction) return;

    this.dialog = true;
  }
}
