

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { ApiResponse } from '@/components/types';
import { FETCH_VOUCHER } from '@/modules/listOfAssets/store/financeAccount';
import { MimeType, Type } from '@/modules/fileManagement/types';
import fileDownload from 'js-file-download';
import PdfView from '@/modules/fileManagement/components/PdfView.vue';
import ImageView from '@/modules/fileManagement/components/ImageView.vue';
import { Transaction } from '@/modules/listOfAssets/types';
import { FETCH_FILE_BY_ID } from "@/modules/fileManagement/store";

const FileManagement = namespace('fileManagement');

@Component({
  components: {
    PdfView,
    ImageView,
    ErrorAlert
  }
})
export default class ViewFileDialog extends Vue {
  @Prop({ type: Object }) transaction!: Transaction;

  @FileManagement.Action(FETCH_FILE_BY_ID) fetchVoucher!: ({ file, type }: { file: string, type: Type }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  dialog: boolean = false;

  loading: boolean = false;
  fileBlob: Blob | null = null;
  fileUrl: string = '';
  view: string = '';

  views = {
    [MimeType.PDF as string]: 'pdf-view',
    [MimeType.PNG as string]: 'image-view',
    [MimeType.JPG as string]: 'image-view',
  };

  download() {
    if (this.fileBlob && this.transaction && this.transaction.voucher) {
      fileDownload(this.fileBlob, this.transaction.voucher.filename);
    }
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.fileBlob = null;
      this.view = '';

      if (this.fileUrl) {
        URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = '';
      }
    }, 200);

    this.$emit('update:transaction', null);
  }

  @Watch('transaction', { immediate: true })
  watchFolder(transaction: Transaction) {
    if (!transaction || !transaction.voucher) return;

    this.loading = true;
    this.view = this.views[transaction.voucher.mimeType];

    this.fetchVoucher({ file: transaction.id, type: Type.local })
      .then(({ content, error }) => {
        if (content) {
          this.fileBlob = content;
          this.fileUrl = URL.createObjectURL(content);
        }

        if (error) {
          this.error = error;
        }

        this.loading = false;
      });

    this.dialog = true;
  }
}
