





















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_BANK_ACCOUNT, GET_BANK_ACCOUNT } from '../store/bankAccount';
import { ApiResponse } from '@/components/types';
import { BankAccountDetails } from '@/modules/listOfAssets/types';
import CardRow from '@/components/CardRow.vue';
import GiroAccountDialog from '../components/details/dialogs/GiroAccountDialog.vue';
import FinanceAccountOpenedDialog from '../components/details/dialogs/FinanceAccountOpenedDialog.vue';
import FinanceAccountStartDepositDialog from '../components/details/dialogs/FinanceAccountStartDepositDialog.vue';
import FinanceAccountCloseDialog from '@/modules/listOfAssets/components/details/dialogs/FinanceAccountCloseDialog.vue';
import TransactionExportDialog from '@/modules/listOfAssets/components/TransactionExportDialog.vue';
import TransactionCard from '@/modules/listOfAssets/components/details/cards/TransactionCard.vue';
import VoucherExportDialog from '@/modules/listOfAssets/components/VoucherExportDialog.vue';
import ImportTable from '@/modules/listOfAssets/components/ImportTable.vue';

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    ImportTable,
    VoucherExportDialog,
    TransactionCard,
    TransactionExportDialog,
    FinanceAccountCloseDialog,
    FinanceAccountStartDepositDialog,
    FinanceAccountOpenedDialog,
    GiroAccountDialog,
    CardRow
  }
})
export default class BankAccountDetailsView extends Vue {
  @BankAccount.Action(FETCH_BANK_ACCOUNT) fetchDetails!: (bankAccount: string) => Promise<ApiResponse>;
  @BankAccount.Getter(GET_BANK_ACCOUNT) getDetails!: (bankAccount: string) => BankAccountDetails | undefined;

  get account(): BankAccountDetails | undefined {
    return this.getDetails(this.$route.params['id']);
  }

  get backURL() {
    if (this.$route.query['referrer']) {
      return this.$route.query['referrer'];
    }

    if (!this.account) return '';

    return { name: 'list-of-assets-details', params: { id: this.account.listOfAssetsId } };
  }

  @Watch('$route.params.id', { immediate: true })
  onIdChange() {
    return this.fetchDetails(this.$route.params['id']);
  }
}
