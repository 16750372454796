import { BankAccountType, CashAccountType, CreditAccountType, InternalAccountType } from './types';

export const GIRO_ACCOUNT: BankAccountType = 'giro_account';
export const SAVINGS_ACCOUNT: BankAccountType = 'savings_account';
export const BUILDING_LOAN_CONTRACT: BankAccountType = 'building_loan_contract';
export const ACCOUNTS_PAYABLE: CreditAccountType = 'accounts_payable';
export const CASH_ACCOUNT: CashAccountType = 'cash_account';
export const OTHER_ACCOUNT: CashAccountType = 'other_account';
export const INTERNAL_ACCOUNT: InternalAccountType = 'internal_account';
export const STOCKS_EQUITY_FUNDS: CreditAccountType = 'stocks_equity_funds';
export const FUNERARY_ACCOUNT: CreditAccountType = 'funerary_account';
export const ACCOUNT_IN_CREDIT: CreditAccountType = 'account_in_credit';
export const DEPOSIT_ACCOUNT: CreditAccountType = 'deposit_account';

export const bankAccount: BankAccountType[] = [GIRO_ACCOUNT, SAVINGS_ACCOUNT, BUILDING_LOAN_CONTRACT];
export const creditAccount: CreditAccountType[] = [STOCKS_EQUITY_FUNDS, DEPOSIT_ACCOUNT, ACCOUNT_IN_CREDIT, FUNERARY_ACCOUNT, ACCOUNTS_PAYABLE];
export const cashAccount: CashAccountType[] = [CASH_ACCOUNT, OTHER_ACCOUNT];
