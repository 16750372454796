












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import { UPDATE_TRANSACTION } from '../../../store/financeAccount';
import { ApiResponse } from '@/components/types';
import { Transaction, UpdateTransaction } from '../../../types';
import { createUpdateTransaction } from '@/modules/listOfAssets/model';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class UpdateTransactionDialog extends Vue {
  @Prop({ type: Object }) transaction!: Transaction | null;

  @FinanceAccount.Action(UPDATE_TRANSACTION) updateTransaction!: (transaction: UpdateTransaction) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: UpdateTransaction | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:transaction', null);
    }, 200);
  }

  @Watch('transaction')
  watchTransaction(transaction: Transaction) {
    if (!transaction) return;

    this.value = createUpdateTransaction(transaction);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.updateTransaction(this.value);
  }
}
