















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class HtmlRow extends Vue {
  @Prop({ required: true }) value!: string | number;
  @Prop({ required: true }) label!: string | string[];
  @Prop({ default: true }) actions!: boolean;
  @Prop() labelInfo?: string;
  @Prop() icon?: string;
  @Prop({ default: 'indigo' }) iconColor!: string;

  get transLabel(): string | TranslateResult {
    if (Array.isArray(this.label)) {
      return this.label.map((label) => this.$i18n.t(label)).join(', ');
    }

    return this.$i18n.t(this.label);
  }
}
