
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_BANK_ACCOUNT } from '../../../store/bankAccount';
import { BankAccountDetails, ChangeBankAccount } from '../../../types';
import { ApiResponse } from '@/components/types';
import BankAccountTypeSelect from '../../BankAccountTypeSelect.vue';
import BankSelect from '../../../../bank/components/BankSelect.vue';
import IbanField from '../../IBANField.vue';
import BankAccountConvertDialog from '@/modules/listOfAssets/components/details/dialogs/BankAccountConvertDialog.vue';

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    BankAccountConvertDialog,
    BankAccountTypeSelect,
    IbanField,
    BankSelect
  }
})
export default class GiroAccountDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: BankAccountDetails;
  @BankAccount.Action(CHANGE_BANK_ACCOUNT) changeBankAccount!: (bankAccount: ChangeBankAccount) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  typeDialog: boolean = false;

  value: ChangeBankAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    iban: this.account.iban,
    bic: this.account.bic,
    bank_id: this.account.bank.id,
    note: this.account.note
  };

  openTypeDialog() {
    this.typeDialog = true;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        iban: this.account.iban,
        bic: this.account.bic,
        bank_id: this.account.bank.id,
        note: this.account.note
      };

      return;
    }

    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('typeDialog')
  watchTypeDialogClosed(dialog: boolean) {
    if (dialog) return;

    this.dialog = false;
  }

  save() {
    this.error = null;

    return this.changeBankAccount(this.value);
  }
}
