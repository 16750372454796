


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CardRow from '@/components/CardRow.vue';
import { CREATE_CONTRA_ENTRY, GET_TRANSACTIONS } from '../../../store/financeAccount';
import FinanceAccountSelect from '@/modules/listOfAssets/components/FinanceAccountSelect.vue';
import { CreateContraEntry, Transaction } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';
import { createContraEntry } from '@/modules/listOfAssets/model';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    CardRow,
    FinanceAccountSelect
  }
})
export default class CreateContraEntryDialog extends Vue {
  @Prop({ type: Object }) transaction!: Transaction | null;
  @Prop({ type: String, required: true }) listOfAssetsId!: string;

  @FinanceAccount.Action(CREATE_CONTRA_ENTRY) createContraEntry!: (params: CreateContraEntry) => Promise<ApiResponse>;
  @FinanceAccount.Getter(GET_TRANSACTIONS) getTransactions!: (financeAccount: string) => { items: Transaction[], page: number };

  error: any = null;
  dialog: boolean = false;
  value: CreateContraEntry | null = null;

  get transactionType() {
    if (!this.transaction) return '';

    if (this.transaction.type === 'withdraw') {
      return this.$i18n.t('transaction.deposit');
    }

    return this.$i18n.t('transaction.withdraw');
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:transaction', null);
    }, 200);
  }

  @Watch('transaction')
  watchTransaction(transaction: Transaction) {
    if (!transaction) return;

    this.value = createContraEntry(this.$auth.user().id, transaction);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  @Watch('value.target_account_id')
  nextVoucherNumber(targetAccount: string): void {
    if (!targetAccount || !this.value) return;

    let nextVoucherNumber: number = 0;

    this.getTransactions(targetAccount).items.forEach((transaction) => {
      const voucherNumber = parseInt(transaction.voucherNumber, 10);

      if (voucherNumber && voucherNumber > nextVoucherNumber) {
        nextVoucherNumber = voucherNumber;
      }
    });

    this.value.voucher_number = (nextVoucherNumber + 1).toString();
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.createContraEntry(this.value);
  }
}
