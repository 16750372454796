

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppDropzone from '@/modules/fileManagement/components/AppDropzone.vue';
import {FinanceAccountDetails} from '@/modules/listOfAssets/types';

@Component({
  components: {
    AppDropzone,
    ErrorAlert
  }
})
export default class VoucherUploadDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: FinanceAccountDetails;
  @Prop({ type: String, required: true }) bank!: string;

  error: any = null;
  dialog: boolean = false;
  close: Function = () => {};

  baseURL: string = process.env.VUE_APP_API || `https://${document.location.host}/backend`;

  get url(): string {
    if (!this.account) return ''

    return `${this.baseURL}/api/financial-administration/bank-accounts/${this.account.id}/${this.bank}-bank`
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    this.error = null;
    this.close();
  }
}
