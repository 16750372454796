




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format, subDays } from '@/filter/dateFormat';
import BankSelect from '../../../../bank/components/BankSelect.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import { IMPORT_SINGLE_ACCOUNT_TRANSACTIONS } from '../../../store/financeAccount';
import { ApiResponse } from '@/components/types';
import { BankAccountDetails, ImportTransactions, Transaction } from '../../../types';
import { createTransactionImport } from '../../../model';
import uuid from 'uuid/v4';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    BankSelect,
    DatePicker
  }
})
export default class ImportTransactionDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: BankAccountDetails;
  @Prop({ type: Array, default: () => [] }) transactions!: Transaction[];
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  @FinanceAccount.Action(IMPORT_SINGLE_ACCOUNT_TRANSACTIONS) importTransactions!: (value: ImportTransactions) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ImportTransactions = createTransactionImport(
    this.$auth.user().id,
    this.account.bank.id,
    undefined,
    undefined,
    this.account.iban,
    this.account.id
  );

  allowed(date: string) {
    return date < this.value.to;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.value = createTransactionImport(
        this.$auth.user().id,
        this.account.bank.id,
        undefined,
        undefined,
        this.account.iban,
        this.account.id
      );

      this.calcFromAndTo();
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return this.importTransactions(this.value).then(r => {
      this.$emit('success')

      return r
    });
  }

  async onError(error: any) {
    this.error = error;
    this.value.import_id = uuid();
  }

  calcFromAndTo() {
    this.transactions.forEach(({ transacted }) => {
      if (!this.value.from) {
        this.value.from = transacted;
      }

      if (this.value.from < transacted) {
        this.value.from = transacted;
      }
    });

    if (!this.value.from) return;

    this.value.from = format(subDays(this.value.from, 2), 'yyyy-MM-dd');
  }

  created() {
    this.calcFromAndTo();
  }
}
