










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import DatePicker from '@/components/form/DatePicker.vue';
import { CLOSE_ACCOUNT } from '../../../store/financeAccount';
import { CloseFinanceAccount, FinanceAccountDetails } from '../../../types';
import { ApiResponse } from '@/components/types';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class StartDepositDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: FinanceAccountDetails;
  @FinanceAccount.Action(CLOSE_ACCOUNT) close!: (value: CloseFinanceAccount) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: CloseFinanceAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    category: this.account.category,
    closed: format(Date.now(), 'yyyy-MM-dd')
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        category: this.account.category,
        closed: format(Date.now(), 'yyyy-MM-dd')
      };

      return;
    }

    setTimeout(() => {
      this.error = null;

      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.close(this.value);
  }
}
