














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IBAN from 'iban';
import { FETCH_LIST, GET_BY_BANK_CODE, GET_BY_NAME } from '@/modules/bank/store';
import { Bank } from '../../bank/types';
import { ApiResponse } from '@/components/types';

const Bank = namespace('bank');

@Component({})
export default class IBANField extends Vue {
  @Bank.Getter(GET_BY_NAME) getByName!: (name: string) => Bank | null;
  @Bank.Getter(GET_BY_BANK_CODE) getByBankCode!: (bankCode: string) => Bank | null;
  @Bank.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  @Prop({ type: String }) value?: string;
  @Prop({ type: String }) placeholder?: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  get validate() {
    return {
      required: this.required,
      isIBAN: true
    };
  }

  @Watch('value')
  onValueChange(value: string | null | undefined) {
    if (!value) return;

    value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1').trim();

    if (IBAN.isValid(value)) {
      const bank = this.getByBankCode(IBAN.toBBAN(value).split(' ')[0]);

      if (bank) {
        this.$emit('bic-response', bank.bic);
        this.$emit('bank-response', bank.id);
      }
    }
  }

  input(value: string) {
    value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

    this.$emit('input', value);
  }

  created() {
    return this.fetch();
  }
}
