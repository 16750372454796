























import { Component, Prop, Vue } from 'vue-property-decorator';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import { namespace } from 'vuex-class';

const Conservatorship = namespace('conservatorship');

@Component({ components: { ListSearchDialog } })
export default class CardHeader extends Vue {
  @Prop({ type: String, default: '' }) value!: string;

  get search(): string {
    return this.value;
  }

  set search(value: string) {
    this.$emit('input', value);
  }
}
