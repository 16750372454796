















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TransactionType, WriteTransaction } from '../../../types';
import { EXECUTE_TRANSACTION } from '../../../store/financeAccount';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';
import { createWriteTransaction } from '@/modules/listOfAssets/model';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    DatePicker
  }
})
export default class CreateTransactionDialog extends Vue {
  @Prop({ type: String, required: true }) account!: string;
  @Prop({ type: String, required: true }) type!: TransactionType;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Array, default: () => [] }) proposals!: string[];
  @Prop({ type: String }) nextVoucherNumber?: string;

  @FinanceAccount.Action(EXECUTE_TRANSACTION) executeTransaction!: (value: WriteTransaction) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: WriteTransaction = createWriteTransaction(this.account, this.$auth.user().id, this.type, this.nextVoucherNumber || '');

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.value = createWriteTransaction(this.account, this.$auth.user().id, this.type, this.nextVoucherNumber || '');

      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('nextVoucherNumber')
  onNextVoucherNumber(nextVoucherNumber: string) {
    this.value.voucher_number = nextVoucherNumber;
  }

  async save() {
    this.error = null;

    return this.executeTransaction(this.value);
  }
}
