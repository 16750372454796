









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_START_DEPOSIT } from '../../../store/financeAccount';
import { ChangeStartDeposit, FinanceAccountDetails } from '../../../types';
import { ApiResponse } from '@/components/types';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({})
export default class FinanceAccountStartDepositDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: FinanceAccountDetails;
  @FinanceAccount.Action(CHANGE_START_DEPOSIT) changeStartDeposit!: (value: ChangeStartDeposit) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: ChangeStartDeposit = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    category: this.account.category,
    money: this.account.startDeposit
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        category: this.account.category,
        money: this.account.startDeposit
      };

      return;
    }

    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeStartDeposit(this.value);
  }
}
