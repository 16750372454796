







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ListSearchDialog extends Vue {
  @Prop({ default: '' }) search!: string;

  dialog: boolean = false;
  iSearch: string = '';

  @Watch('search', { immediate: true })
  onSearch(search = '') {
    this.iSearch = search;
  }

  submit(event: Event) {
    event.preventDefault();

    this.$emit('update:search', this.iSearch);
    this.dialog = false;
  }

  reset() {
    this.iSearch = this.search;
    this.dialog = false;
  }
}
